import Loading from 'components/common/Loading';
import React from 'react';
import styled from 'styled-components';
import CLELibraryPageHeader from '../CLELibraryPageHeader';
import { CLELibrarySearchResults } from './CLELibrarySearchResults';
import { CLELibrarySearchResult } from './CLELibrarySearchResult';
import { CLELibrarySearchSidebar } from './CLELibrarySearchSidebar';
import { usePracticeAreaRootSlugs } from '../usePracticeAreaRootSlugs';
import CLELibraryHeaderTabNavigation from '../CLELibraryHeaderTabNavigation';

export function CLELibraryLanding() {
  const rootSlugs = usePracticeAreaRootSlugs();

  return (
    <div>
      <CLELibraryPageHeader>
        <CLELibraryHeaderTabNavigation />
      </CLELibraryPageHeader>
      <WithSidebar>
        <CLELibrarySearchSidebar />
        <Loading>
          <CLELibrarySearchResults>
            {result => (
              <CLELibrarySearchResult
                key={`course-result-${result.contentfulId}`}
                rootSlugs={rootSlugs}
                data={result}
                path={`/course/${result.slug}`}
                courseSearchBase="/"
              />
            )}
          </CLELibrarySearchResults>
        </Loading>
      </WithSidebar>
    </div>
  );
}

const WithSidebar = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'sidebar main';
  height: 100%;
  width: 100%;
`;
