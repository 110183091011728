import React from 'react';
import { ApolloProvider } from '@apollo/client';
import Loading from 'components/common/Loading';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from 'components/app/ScrollToTop';
import { CLELibraryLanding } from './landing';
import { AppContext } from 'components/app-context';
import styled from 'styled-components';
import CLELibraryCourse from './course';
import CLELibraryPackages from './packages';
import CLELibraryPackage from './package';
import { useIntercomDefaultLauncher } from 'components/layouts/useIntercomWidget';
import { CEB_PORTAL_INTERCOM_APP_ID, CEB_PORTAL_GA_ID } from 'app-constants';
import { IntercomProvider } from 'react-use-intercom';
import TagManager from 'react-gtm-module';
import { StyledGlobalApp } from 'components/app/StyledGlobalApp';

const AppContextProvider = ({ children }) => {
  useIntercomDefaultLauncher();

  if (CEB_PORTAL_GA_ID) {
    TagManager.initialize({
      gtmId: CEB_PORTAL_GA_ID,
    });
  }

  return <AppContext.Provider value={{}}>{children}</AppContext.Provider>;
};

export default function CLELibrary({ client }) {
  return (
    <IntercomProvider
      shouldInitialize={CEB_PORTAL_INTERCOM_APP_ID !== undefined}
      appId={CEB_PORTAL_INTERCOM_APP_ID}
      autoBoot={CEB_PORTAL_INTERCOM_APP_ID !== undefined}
      autoBootProps={{ hideDefaultLauncher: true }}
    >
      <StyledContainer className="public-cle container px-3 mx-auto xl:px-0">
        <StyledContent>
          <HelmetProvider>
            <ApolloProvider client={client}>
              <Loading>
                <Router>
                  <ScrollToTop />
                  <AppContextProvider>
                    <Loading>
                      <Routes>
                        <Route path="/" element={<CLELibraryLanding />} />
                        <Route
                          path="/course/:slug"
                          element={<CLELibraryCourse />}
                        />
                        <Route path="/collections/packages">
                          <Route path="" element={<CLELibraryPackages />} />
                          <Route path=":slug" element={<CLELibraryPackage />} />
                        </Route>
                      </Routes>
                    </Loading>
                  </AppContextProvider>
                </Router>
              </Loading>
            </ApolloProvider>
          </HelmetProvider>
        </StyledContent>
      </StyledContainer>
      <StyledGlobalApp />
    </IntercomProvider>
  );
}

const StyledContainer = styled.div``;

const StyledContent = styled.div`
  padding-top: 105px;
`;
